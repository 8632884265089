import api from './index'

export default {
  initializeVideo(id_corporation) {
    return api.post('/videos/initialize', { id_corporation });
  },

  getUploadUrl({ id_video, id_corporation, filename, contentType }) {
    return api.post('/videos/upload-url', { 
      id_video, 
      id_corporation,
      filename, 
      contentType 
    });
  },

  uploadToS3(file, uploadUrl, onProgress) {
    return api.put(uploadUrl, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        onProgress(Math.round(progress));
      }
    });
  },

  startVideoProcessing(id_video) {
    return api.post(`/videos/${id_video}/process`);
  },

  getVideoByModuleId(id_course_module) {
    return api.get(`/videos/module/${id_course_module}`);
  }
};
