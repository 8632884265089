<template>
  <div class="min-h-screen bg-[#F3F4F5]" @click="handleOutsideClick">
    <!-- Hero Section -->
    <div class="max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
      <div class="text-center">
        <span class="inline-block bg-[#D8CAE6] text-[#102340] px-4 py-2 rounded-full text-sm font-medium mb-4">
          For Busy Professionals & Experts
        </span>
        <h1 class="text-4xl md:text-6xl lg:text-[80px] font-light font-olney text-[#102340] mb-6 leading-tight text-left flex flex-col">
          <span class="block mt-2">Grow your professional <span class="text-[#EB3B38]">brand.</span></span>
          <span class="block">Share your <span class="text-[#EB3B38]">expertise.</span></span>
          <span class="block mt-2">Monetize your <span class="text-[#EB3B38]">services.</span></span>
        </h1>
        <p class="text-[28px] font-asap text-[#6D6E70] mb-4 max-w-2xl mx-auto">
        The platform for guided expert conversations.
        </p>
        <!-- Add Video Tiles Grid -->
        <div class="mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
          <!-- Video Tile 1 -->
          <div 
            class="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer transition-all duration-300 h-[250px]"
            :class="{ 'hover:shadow-xl': true }"
            @mouseenter="playVideo($event.currentTarget)"
            @mouseleave="pauseVideo($event.currentTarget)"
          >
            <div class="transform transition-transform duration-500 group-hover:scale-110">
              <video 
                :id="`video-0`"
                class="w-full h-[250px] object-cover object-[center_30%]"
                poster="/videos/thumbnails/eduardo_ramirez.png"
                muted
                loop
                playsinline
                preload="none"
              >
                <source src="/videos/experts/Eduardo_ramirez_Getting_Ready_for_AI.mp4" type="video/mp4">
              </video>
            </div>
            
            <!-- Always visible play button with hover effect -->
            <div class="absolute inset-0 flex items-center justify-center transition-opacity duration-300 group-hover:opacity-0">
              <div class="w-16 h-16 rounded-full bg-[#EB3B38]/80 flex items-center justify-center">
                <i class="fas fa-play text-white text-2xl"></i>
              </div>
            </div>
            
            <!-- Info overlay with gradient -->
            <div class="absolute inset-0 bg-gradient-to-t from-[#102340]/80 via-transparent to-transparent transition-opacity duration-300 group-hover:opacity-90">
              <div class="absolute bottom-0 left-0 p-4 text-left">
                <h3 class="text-white font-asap text-lg">Director of Artificial Intelligence</h3>
                <p class="text-[#D8CAE6] text-sm font-asap">30-minute knowledge sharing</p>
              </div>
            </div>
            
            <!-- LinkedIn button -->
            <div class="absolute top-4 right-4 z-10">
              <a 
                href="https://www.linkedin.com/in/ehramirez/" 
                target="_blank" 
                rel="noopener noreferrer"
                class="w-10 h-10 rounded-full bg-white/90 flex items-center justify-center hover:bg-white transition-colors"
              >
                <i class="fab fa-linkedin-in text-[#0A66C2] text-xl"></i>
              </a>
            </div>
          </div>

          <!-- Video Tile 2 -->
          <div 
            class="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer transition-all duration-300 h-[250px]"
            :class="{ 'hover:shadow-xl': true }"
            @mouseenter="playVideo($event.currentTarget)"
            @mouseleave="pauseVideo($event.currentTarget)"
          >
            <div class="transform transition-transform duration-500 group-hover:scale-110">
              <video 
                :id="`video-1`"
                class="w-full h-[250px] object-cover object-[center_30%]"
                poster="/videos/thumbnails/hai_cats_with_baths.png"
                muted
                loop
                playsinline
                preload="none"
              >
                <source src="/videos/experts/Hai_cats_with bats_ ai-powered_learning_platform.mp4" type="video/mp4">
              </video>
            </div>
            <!-- Always visible play button -->
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="w-16 h-16 rounded-full bg-[#EB3B38]/80 flex items-center justify-center">
                <i class="fas fa-play text-white text-2xl"></i>
              </div>
            </div>
            <!-- Always visible info with hover gradient -->
            <div class="absolute inset-0 bg-gradient-to-t from-[#102340]/80 via-transparent to-transparent">
              <div class="absolute bottom-0 left-0 p-4 text-left">
                <h3 class="text-white font-asap text-lg">Cats with Bats</h3>
                <p class="text-[#D8CAE6] text-sm font-asap">Building community through expertise</p>
              </div>
            </div>
            <!-- Additional hover gradient for emphasis -->
            <div class="absolute inset-0 bg-gradient-to-t from-[#102340]/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            <div class="absolute top-4 right-4 z-10">
              <a 
                href="https://www.linkedin.com/in/haiphunghiem/" 
                target="_blank" 
                rel="noopener noreferrer"
                class="w-10 h-10 rounded-full bg-white/90 flex items-center justify-center hover:bg-white transition-colors"
                @click.stop
              >
                <i class="fab fa-linkedin-in text-[#0A66C2] text-xl"></i>
              </a>
            </div>
          </div>

          <!-- Video Tile 3 -->
          <div 
            class="relative group overflow-hidden rounded-lg shadow-lg cursor-pointer transition-all duration-300 h-[250px]"
            :class="{ 'hover:shadow-xl': true }"
            @mouseenter="playVideo($event.currentTarget)"
            @mouseleave="pauseVideo($event.currentTarget)"
          >
            <div class="transform transition-transform duration-500 group-hover:scale-110">
              <video 
                :id="`video-2`"
                class="w-full h-[250px] object-cover object-[center_30%]"
                poster="/videos/thumbnails/hassaan_analyst.png"
                muted
                loop
                playsinline
                preload="none"
              >
                <source src="/videos/experts/Hassaan_think_like and analyst.mp4" type="video/mp4">
              </video>
            </div>
            <!-- Always visible play button -->
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="w-16 h-16 rounded-full bg-[#EB3B38]/80 flex items-center justify-center">
                <i class="fas fa-play text-white text-2xl"></i>
              </div>
            </div>
            <!-- Always visible info with hover gradient -->
            <div class="absolute inset-0 bg-gradient-to-t from-[#102340]/80 via-transparent to-transparent">
              <div class="absolute bottom-0 left-0 p-4 text-left">
                <h3 class="text-white font-asap text-lg">Professional Growth</h3>
                <p class="text-[#D8CAE6] text-sm font-asap">Scaling expertise globally</p>
              </div>
            </div>
            <!-- Additional hover gradient for emphasis -->
            <div class="absolute inset-0 bg-gradient-to-t from-[#102340]/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            <div class="absolute top-4 right-4 z-10">
              <a 
                href="https://www.linkedin.com/in/hassaan-khan-787695126/" 
                target="_blank" 
                rel="noopener noreferrer"
                class="w-10 h-10 rounded-full bg-white/90 flex items-center justify-center hover:bg-white transition-colors"
                @click.stop
              >
                <i class="fab fa-linkedin-in text-[#0A66C2] text-xl"></i>
              </a>
            </div>
          </div>
        </div>
        <a 
          href="https://calendly.com/shane-maley/30min"
          target="_blank"
          rel="noopener noreferrer" 
          class="mt-12 inline-flex items-center px-8 py-4 rounded-lg bg-[#EB3B38] text-white font-asap font-medium text-[18px] hover:bg-[#d43532] transition-colors"
        >
          Start The Apprendo Talk<span class="text-[16px] align-super">™</span>
          <i class="ml-2 fas fa-arrow-right text-xl"></i>
        </a>
      </div>
    </div>

    <!-- WHY Apprendo Section (simplified) -->
    <div class="bg-white py-16">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-[42px] font-light font-olney text-[#102340] text-center mb-12">
          Expertise delivered in many forms. <span class="block text-[#EB3B38]">What's yours?</span>
        </h2>
        <div class="grid md:grid-cols-3 gap-8">
          <!-- Personal Brand Growth -->
          <div class="card relative">
            <div class="p-8">
              <!-- Existing Profile Section -->
              <div class="flex items-center mb-6">
                <img src="/videos/thumbnails/Eduardo.jpeg" alt="Eduardo Ramirez" class="w-16 h-16 rounded-full" />
                <div class="ml-4">
                  <h3 class="text-xl font-semibold text-[#102340]">Eduardo Ramirez</h3>
                  <p class="text-[#6D6E70]">Director of Artificial Intelligence, Ensitech</p>
                </div>
              </div>
              <div class="mb-6">
                <p class="text-[#6D6E70] italic mb-4">
                  "I've tripled my consultation requests and landed a dream contract."
                </p>
                <div class="flex items-center justify-between text-[#102340]">
                  <span class="font-semibold">Visibility Growth</span>
                  <span class="text-[#EB3B38]">+200%</span>
                </div>
              </div>
              <div class="border-t pt-4">
                <p class="text-sm text-[#6D6E70]">
                  From limited visibility to recognized industry expert through consistent Apprendo Talks
                </p>
              </div>
            </div>
          </div>

          <!-- Knowledge Monetization -->
          <div class="card relative">
            <div class="p-8">
              <div class="flex items-center mb-6">
                <img src="/videos/thumbnails/Efrain.jpeg" alt="Efrain Zapata" class="w-16 h-16 rounded-full" />
                <div class="ml-4">
                  <h3 class="text-xl font-semibold text-[#102340]">Efrain Zapata</h3>
                  <p class="text-[#6D6E70]">CEO, Saphi Solutions</p>
                </div>
              </div>
              <div class="mb-6">
                <p class="text-[#6D6E70] italic mb-4">
                  "Apprendo has transformed my leadership insights into a thriving community, turning my client base into subscribers."
                </p>
                <div class="flex items-center justify-between text-[#102340]">
                  <span class="font-semibold">Monthly Revenue</span>
                  <span class="text-[#EB3B38]">$15K+</span>
                </div>
              </div>
              <div class="border-t pt-4">
                <p class="text-sm text-[#6D6E70]">
                  From occasional workshops to a scalable knowledge-sharing platform
                </p>
              </div>
            </div>
          </div>

          <!-- Institutional Growth -->
          <div class="card relative">
            <div class="p-8">
              <div class="flex items-center mb-6">
                <img src="/videos/thumbnails/imefLogo.png" alt="Financial Experts Association" class="w-17 h-16 rounded-full" />
                <div class="ml-4">
                  <h3 class="text-xl font-semibold text-[#102340]">IMEF</h3>
                  <p class="text-[#6D6E70]">Professional Association</p>
                </div>
              </div>
              <div class="mb-6">
                <p class="text-[#6D6E70] italic mb-4">
                  "Highlighting our experts through Apprendo has revolutionized the value we deliver to our members. Our community of professionals are now recognized as thought leaders and are growing their practices, making our association essential to the professionals we serve."
                </p>
                <div class="flex items-center justify-between text-[#102340]">
                  <span class="font-semibold">Membership Growth</span>
                  <span class="text-[#EB3B38]">+150%</span>
                </div>
              </div>
              <div class="border-t pt-4">
                <p class="text-sm text-[#6D6E70]">
                  From traditional association to impactful, go-to expert network
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- WHAT Section -->
    <div class="py-16 bg-white">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-[42px] font-light font-olney text-[#102340] text-center mb-4">
          The complete platform for knowledge monetization,
        </h2>
        <h3 class="text-[32px] font-light font-olney text-[#EB3B38] text-center mb-12">
          powered by:
        </h3>

        <!-- Core Features -->
        <div class="grid md:grid-cols-3 gap-8 mb-16">
          <div class="card p-8">
            <h4 class="text-xl font-semibold text-[#102340] mb-4">The Apprendo Talk<span class="text-[12px] align-super">™</span></h4>
            <p class="text-[#6D6E70]">Our signature interview process that extracts and transforms expertise through natural conversation</p>
          </div>

          <div class="card p-8">
            <h4 class="text-xl font-semibold text-[#102340] mb-4">Automatic Distribution</h4>
            <p class="text-[#6D6E70]">Direct LinkedIn posting and smart content distribution to your audience</p>
          </div>

          <div class="card p-8">
            <h4 class="text-xl font-semibold text-[#102340] mb-4">Revenue Engine</h4>
            <p class="text-[#6D6E70]">Turn followers into subscribers with custom monetization portals</p>
          </div>
        </div>

        <!-- For Experts & Organizations -->
        <div class="grid md:grid-cols-2 gap-12 mb-16">
          <!-- For Experts -->
          <div class="card p-8">
            <h4 class="text-2xl font-light font-olney text-[#102340] mb-6">For Experts</h4>
            <ul class="space-y-4">
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Weekly guided expert interviews</span>
                  <p class="text-[#6D6E70] text-sm mt-1">30-minute conversations that create weeks of content</p>
                </div>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Automated content creation & distribution</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Direct LinkedIn posting and subscriber sharing</p>
                </div>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Audience monetization</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Convert followers to paying subscribers</p>
                </div>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Performance analytics</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Track engagement, growth, and revenue</p>
                </div>
              </li>
            </ul>
          </div>

          <!-- For Organizations -->
          <div class="card p-8">
            <h4 class="text-2xl font-light font-olney text-[#102340] mb-6">For Organizations</h4>
            <ul class="space-y-4">
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Custom-branded portals</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Your brand, your experience, our technology</p>
                </div>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Professional billing</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Enterprise-grade payment processing</p>
                </div>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Team management</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Multi-expert coordination and oversight</p>
                </div>
              </li>
              <li class="flex items-start">
                <i class="fas fa-check text-[#EB3B38] mt-1 mr-3"></i>
                <div>
                  <span class="font-semibold text-[#102340]">Enterprise integrations</span>
                  <p class="text-[#6D6E70] text-sm mt-1">Connect with your existing systems</p>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <!-- Stats Grid -->
        <div class="grid md:grid-cols-4 gap-8">
          <div class="card text-center p-8">
            <h4 class="text-[42px] font-light font-olney text-[#EB3B38] mb-2">30 min</h4>
            <p class="text-[#6D6E70] font-asap">Weekly Talk</p>
          </div>
          
          <div class="card text-center p-8">
            <h4 class="text-[42px] font-light font-olney text-[#EB3B38] mb-2">100%</h4>
            <p class="text-[#6D6E70] font-asap">Automated</p>
          </div>
          
          <div class="card text-center p-8">
            <h4 class="text-[42px] font-light font-olney text-[#EB3B38] mb-2">24/7</h4>
            <p class="text-[#6D6E70] font-asap">Distribution</p>
          </div>

          <div class="card text-center p-8">
            <h4 class="text-[42px] font-light font-olney text-[#EB3B38] mb-2">1-Click</h4>
            <p class="text-[#6D6E70] font-asap">LinkedIn Sharing</p>
          </div>
        </div>
      </div>
    </div>

    <!-- HOW Section (replacing Results Section) -->
    <div class="py-16 bg-[#F3F4F5]">
      <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-[42px] font-light font-olney text-[#102340] text-center mb-4">
          Share your expertise through natural conversation, <span class="block text-[#EB3B38]">we handle everything else.</span>
        </h2>
        
        <div class="grid md:grid-cols-3 gap-8 mb-16">
          <!-- Done With Just a Talk -->
          <div class="card">
            <div class="p-8">
              <div class="flex items-center mb-6">
                <span class="text-[#EB3B38] font-olney text-2xl">01</span>
                <h3 class="text-xl font-semibold text-[#102340] ml-4">Done With Just a Talk</h3>
              </div>
              <p class="text-[#6D6E70]">
                No writing required. One 30-minute conversation creates weeks of premium content.
              </p>
            </div>
          </div>

          <!-- Complete Automation -->
          <div class="card">
            <div class="p-8">
              <div class="flex items-center mb-6">
                <span class="text-[#EB3B38] font-olney text-2xl">02</span>
                <h3 class="text-xl font-semibold text-[#102340] ml-4">Complete Automation</h3>
              </div>
              <p class="text-[#6D6E70]">
                We create, promote, deliver, and monetize everything. You just show up and talk.
              </p>
            </div>
          </div>

          <!-- Enterprise-Grade Power -->
          <div class="card">
            <div class="p-8">
              <div class="flex items-center mb-6">
                <span class="text-[#EB3B38] font-olney text-2xl">03</span>
                <h3 class="text-xl font-semibold text-[#102340] ml-4">Enterprise-Grade Power</h3>
              </div>
              <p class="text-[#6D6E70]">
                Custom portals, professional billing, and personalized delivery for your key clients.
              </p>
            </div>
          </div>
        </div>


      </div>
    </div>



    <!-- CTA Section -->
    <div class="py-16 bg-[#EB3B38]">
      <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 class="text-[42px] font-light font-olney text-white mb-6">
          Ready to Scale Your Expertise?
        </h2>
        <p class="text-xl text-blue-100 mb-8">
          Join experts who are growing their revenue with just 30 minutes per week
        </p>
        <div class="space-x-4">
          <a 
            href="https://calendly.com/shane-maley/30min"
            target="_blank"
            rel="noopener noreferrer"
            class="inline-flex items-center px-6 py-3 rounded-lg bg-white text-blue-600 font-semibold hover:bg-blue-50 transition-colors"
          >
            Start Your Apprendo Talk
            <i class="ml-2 fas fa-arrow-right w-5 h-5"></i>
          </a>
          <!-- Removed Watch Demo button -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const playVideo = (container) => {
  const video = container.querySelector('video')
  if (video) {
    video.play()
  }
}

const pauseVideo = (container) => {
  const video = container.querySelector('video')
  if (video) {
    video.pause()
    video.currentTime = 0
  }
}
</script>

<style scoped>
.card {
  @apply bg-white rounded-lg shadow-md;
  transition: all 0.2s ease-in-out;
}

.card:hover {
  @apply shadow-lg;
  transform: translateY(-2px);
}

/* Font Awesome icon sizing */
.fas {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: 900;
}

/* Ensure smooth transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
</style>