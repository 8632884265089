<template>
  <div class="video-create">
    <div v-if="$store.state.videos.loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <div class="video-header">
    </div>
    
    <div class="video-content">
      <div class="upload-section">
        <div class="upload-box"
          @dragenter.prevent="handleDragEnter"
          @dragover.prevent="handleDragOver"
          @dragleave.prevent="handleDragLeave"
          @drop.prevent="handleDrop"
          :class="{ 'drag-over': isDragging }"
        >
          <div class="upload-icon">
            <!-- Calming cloud upload icon -->
            <svg xmlns="http://www.w3.org/2000/svg" class="upload-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
            </svg>
          </div>
          <h2>Select video file to upload</h2>
          <p class="upload-text">Or drag and drop video file</p>
          
          <div class="upload-actions">
            <button class="btn-action" @click="startRecording">
              <svg xmlns="http://www.w3.org/2000/svg" class="action-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
              Record
            </button>
            <button class="btn-action primary" @click="triggerFileUpload">
              <svg xmlns="http://www.w3.org/2000/svg" class="action-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
              </svg>
              Select file
            </button>
          </div>
          
          <input 
            type="file" 
            ref="fileInput"
            @change="handleFileSelect" 
            accept="video/*"
            class="hidden"
          >
          
          <p class="upload-hint">Minimize processing time</p>
          <p class="upload-support">Supported video files are MP4, MOV, AVI</p>
          
          <!-- Add progress indicator -->
          <div v-if="isUploading" class="upload-progress">
            <div class="progress-bar" :style="{ width: `${uploadProgress}%` }"></div>
            <span>{{ Math.round(uploadProgress) }}%</span>
          </div>
        </div>
      </div>
      <div class="editor-container">
        <!-- Editor Menu Bar - Moved to top -->
        <div class="editor-menu-bar">
          <button @click="descriptionEditor.chain().focus().undo().run()">
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24"><path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"/></svg>
          </button>
          <button @click="descriptionEditor.chain().focus().redo().run()">
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24"><path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z"/></svg>
          </button>
          <div class="separator"></div>
          <button 
            @click="descriptionEditor.chain().focus().toggleBold().run()"
            :class="{ 'is-active': descriptionEditor.isActive('bold') }"
          >B</button>
          <button 
            @click="descriptionEditor.chain().focus().toggleItalic().run()"
            :class="{ 'is-active': descriptionEditor.isActive('italic') }"
          >I</button>
          <button 
            @click="descriptionEditor.chain().focus().toggleStrike().run()"
            :class="{ 'is-active': descriptionEditor.isActive('strike') }"
          >S</button>
          <button 
            @click="descriptionEditor.chain().focus().toggleCode().run()"
            :class="{ 'is-active': descriptionEditor.isActive('code') }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24"><path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z"/></svg>
          </button>
          <div class="separator"></div>
          <button 
            @click="descriptionEditor.chain().focus().toggleBulletList().run()"
            :class="{ 'is-active': descriptionEditor.isActive('bulletList') }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24"><path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5S3.17 7.5 4 7.5 5.5 6.83 5.5 6 4.83 4.5 4 4.5zm0 12c-.83 0-1.5.68-1.5 1.5s.68 1.5 1.5 1.5 1.5-.68 1.5-1.5-.67-1.5-1.5-1.5zM7 19h14v-2H7v2zm0-6h14v-2H7v2zm0-8v2h14V5H7z"/></svg>
          </button>
          <button 
            @click="descriptionEditor.chain().focus().toggleOrderedList().run()"
            :class="{ 'is-active': descriptionEditor.isActive('orderedList') }"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="menu-icon" viewBox="0 0 24 24"><path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z"/></svg>
          </button>
        </div>

        <!-- Title Editor -->
        <div class="editor-title">
          <editor-content :editor="titleEditor" />
        </div>

        <!-- Subtitle Editor -->
        <div class="editor-subtitle">
          <editor-content :editor="subtitleEditor" />
        </div>

        <!-- Description Editor -->
        <div class="editor-description">
          <editor-content :editor="descriptionEditor" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import { mapActions } from 'vuex'

export default {
  name: 'VideoCreate',
  components: {
    EditorContent,
  },
  data() {
    return {
      titleEditor: null,
      subtitleEditor: null,
      descriptionEditor: null,
      selectedFile: null,
      id_video: null,
      id_course_module: null,
      videoValidation: {
        maxSize: 5 * 1024 * 1024 * 1024, // 5GB
        minSize: 75 * 1024, // 75KB
        supportedFormats: ['video/mp4', 'video/quicktime', 'video/x-msvideo'],
        minDuration: 3, // seconds
        maxDuration: 3600, // 60 minutes
        minResolution: { width: 256, height: 144 },
        maxResolution: { width: 4096, height: 2304 }
      },
      uploadProgress: 0,
      isUploading: false,
      isDragging: false,
      isActive: true,
    }
  },
  async created() {
    try {
      const moduleId = this.$route.params.id_course_module;
      console.log("1. Module ID:", moduleId);
      
      // Strict numeric validation
      if (!moduleId || !/^\d+$/.test(moduleId)) {
        console.log("2. Invalid module ID");
        this.$toast.error('Invalid course module ID');
        this.$router.push('/dashboard');
        return;
      }

      this.id_course_module = Number(moduleId);
      console.log("3. Converted module ID:", this.id_course_module);
      
      // Get existing video information
      console.log("4. About to dispatch getVideoByModuleId");
      const response = await this.$store.dispatch('videos/getVideoByModuleId', this.id_course_module);
      console.log("5. Response received:", response);
      
      // Check if response exists and has data
      if (response?.data) {
        console.log("6. Response has data");
        this.id_video = response.data.id_video;
        this.isActive = response.data.is_active ?? true;
      } else {
        console.log("6. No data in response");
      }
    } catch (error) {
      console.error("7. Error caught:", error);
      this.$toast.error('Failed to initialize video creation');
      this.$router.push('/dashboard');
    } finally {
      this.setTheme(false);
    }
  },
  beforeMount() {
    this.initializeEditors();
  },
  beforeUnmount() {
    this.destroyEditors();
  },
  methods: {
    ...mapActions('theme', ['setTheme']),
    ...mapActions('videos', ['createVideoInterview']),
    
    async handleSubmit() {
      if (!this.selectedFile) {
        // Handle error - no file selected
        return;
      }

      try {
        const content = {
          title: this.titleEditor.getHTML(),
          subtitle: this.subtitleEditor.getHTML(),
          description: this.descriptionEditor.getHTML(),
          videoFile: this.selectedFile
        };

        console.log('Auth state:', JSON.parse(localStorage.getItem('authState')));

        await this.createVideoInterview(content);
        // Redirect to user profile or video page after successful creation
        this.$router.push(`/@${this.$store.state.auth.user.unique_handler}`);
      } catch (error) {
        console.error('Error creating video interview:', error);
        // Handle error - show user feedback
      }
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    async handleFileSelect(event) {
      const file = event.target.files[0];
      if (file) {
        this.selectedFile = file;
        console.log('Selected file:', file);
        // Trigger upload process immediately after file selection
        await this.handleFileUpload();
      }
    },
    startRecording() {
      console.log('Start recording clicked');
      // Recording functionality will be implemented later
    },
    async validateVideoFile(file) {
      if (!file) return { valid: false, error: 'No file selected' };
      
      // Check file size
      if (file.size > this.videoValidation.maxSize) {
        return { valid: false, error: 'File size exceeds 5GB limit' };
      }
      if (file.size < this.videoValidation.minSize) {
        return { valid: false, error: 'File size must be at least 75KB' };
      }

      // Check format
      if (!this.videoValidation.supportedFormats.includes(file.type)) {
        return { valid: false, error: 'Unsupported file format' };
      }

      // Check video metadata (resolution, duration)
      try {
        const metadata = await this.getVideoMetadata(file);
        if (!this.isValidResolution(metadata.width, metadata.height)) {
          return { valid: false, error: 'Invalid video resolution' };
        }
        if (!this.isValidDuration(metadata.duration)) {
          return { valid: false, error: 'Video duration must be between 3 seconds and 60 minutes' };
        }
        return { valid: true, metadata };
      } catch (error) {
        return { valid: false, error: 'Failed to validate video metadata' };
      }
    },
    async handleFileUpload() {
      if (!this.selectedFile) {
        this.$toast.error('Please select a file first');
        return;
      }

      try {
        this.isUploading = true;
        
        // Get presigned URL for upload
        const uploadUrl = await this.$store.dispatch('videos/getUploadUrl', {
          id_video: this.id_video, // Use the id_video already set during component creation
          filename: this.selectedFile.name,
          contentType: this.selectedFile.type
        });

        // Upload to S3
        await this.$store.dispatch('videos/uploadVideo', {
          file: this.selectedFile,
          uploadUrl,
          onProgress: (progress) => {
            this.uploadProgress = progress;
          }
        });

        // Start processing
        await this.$store.dispatch('videos/startProcessing', { 
          id_video: this.id_video 
        });

        this.$toast.success('Video uploaded successfully');
      } catch (error) {
        console.error('Upload failed:', error);
        const errorMessage = error.message || 'Failed to upload video';
        this.$toast.error(errorMessage);
      } finally {
        this.isUploading = false;
      }
    },
    handleDragEnter(event) {
      event.preventDefault();
      if (event.dataTransfer.items && event.dataTransfer.items[0].kind === 'file') {
        this.isDragging = true;
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      if (event.dataTransfer.items && event.dataTransfer.items[0].kind === 'file') {
        this.isDragging = true;
      }
    },
    handleDragLeave(event) {
      event.preventDefault();
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX;
      const y = event.clientY;
      
      // Only set isDragging to false if we've actually left the drop zone
      if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom) {
        this.isDragging = false;
      }
    },
    async handleDrop(event) {
      event.preventDefault();
      this.isDragging = false;
      
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('video/')) {
        this.selectedFile = file;
        await this.handleFileUpload();
      } else {
        this.$toast.error('Please drop a valid video file');
      }
    },
    initializeEditors() {
      // Initialize all editors
      this.titleEditor = new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Title'
          })
        ],
        editorProps: {
          attributes: {
            class: 'title-editor'
          }
        }
      });

      this.subtitleEditor = new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Subtitle'
          })
        ],
        editorProps: {
          attributes: {
            class: 'subtitle-editor'
          }
        }
      });

      this.descriptionEditor = new Editor({
        extensions: [
          StarterKit,
          Placeholder.configure({
            placeholder: 'Description'
          })
        ],
        editorProps: {
          attributes: {
            class: 'description-editor'
          }
        }
      });
    },
    destroyEditors() {
      // Safely destroy editors if they exist
      if (this.titleEditor) {
        this.titleEditor.destroy();
      }
      if (this.subtitleEditor) {
        this.subtitleEditor.destroy();
      }
      if (this.descriptionEditor) {
        this.descriptionEditor.destroy();
      }
    }
  }
}
</script>

<style scoped>
.video-create {
  --local-bg: #ffffff;
  --local-text: #2c3e50;
  
  background-color: var(--local-bg);
  color: var(--local-text);
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.video-header {
  margin-bottom: 30px;
}

.upload-box {
  border: 1px solid var(--border-color);
  border-radius: 12px;
  padding: 3rem;
  text-align: center;
  margin-bottom: 2rem;
  background-color: var(--bg-secondary);
  transition: all 0.3s ease;
}

.upload-box:hover {
  border-color: #666;
}

.upload-box.drag-over {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.05);
  transform: scale(1.02);
}

.upload-icon {
  margin-bottom: 1.5rem;
}

.upload-svg {
  width: 64px;
  height: 64px;
  color: #666;
  opacity: 0.8;
}

.btn-action {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: transparent;
  color: var(--text-primary);
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-action:hover {
  background: var(--bg-secondary);
  border-color: #666;
}

.btn-action.primary {
  background-color: #f0f0f0;
  color: #333;
}

.btn-action.primary:hover {
  background-color: #e0e0e0;
}

.action-icon {
  width: 20px;
  height: 20px;
}

.upload-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 2rem 0;
}

.upload-hint {
  color: #666;
  margin-top: 1.5rem;
  font-size: 0.9rem;
}

.upload-support {
  color: #999;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}

.upload-text {
  color: #666;
  margin: 0.5rem 0;
  font-size: 0.95rem;
}

h2 {
  font-weight: 500;
  color: var(--text-primary);
  margin-bottom: 0.5rem;
}

.video-details {
  margin-top: 30px;
}

.title-input, .subtitle-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-bottom: 2px solid var(--border-color);
  font-size: 1.2em;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.description-input {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: none;
  border-bottom: 2px solid var(--border-color);
  resize: vertical;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

:deep(.title-editor) {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

:deep(.title-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

:deep(.subtitle-editor) {
  font-size: 1.5em;
  color: #666;
  margin-bottom: 1.5em;
}

:deep(.subtitle-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

:deep(.description-editor) {
  font-size: 1.1em;
  color: var(--text-primary);
  margin-bottom: 1.5em;
}

:deep(.description-editor p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

.editor-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: var(--bg-primary);
  border-radius: 8px;
}

:deep(.ProseMirror) {
  outline: none;
  min-height: 1.5em;
}

:deep(.ProseMirror p) {
  margin: 0;
}

.editor-menu-bar {
  display: flex;
  padding: 0.75rem;
  background: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 2rem;
  gap: 0.5rem;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.menu-icon {
  width: 20px;
  height: 20px;
}

.separator {
  width: 1px;
  background-color: var(--border-color);
  margin: 0 0.5rem;
}

button {
  padding: 0.5rem;
  border: none;
  background: none;
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: var(--bg-secondary);
}

button.is-active {
  background-color: var(--bg-secondary);
  color: #ff4500;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #007bff;
  border-top: 4px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.upload-progress {
  margin-top: 1rem;
  background: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  height: 20px;
}

.progress-bar {
  background: #007bff;
  height: 100%;
  transition: width 0.3s ease;
}

.drag-over {
  border-color: #666;
}
</style>
